import React from 'react';
import Layout from '../components/Layout';
import Section from '../components/Section';
import Container from '../components/Container';

const TermsAndConditions = () => {
  return (
    <Layout withScroll={false}>
      <Section className="bg-secondary">
        <div id="features" />

        <Container>
          <h2 className="pt-20 text-2xl font-strong py-7 text-center">
            Terms and Conditions
          </h2>
          <p className="pb-6 text-l font-thin text-center">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Luctus
            venenatis lectus magna fringilla urna. Aliquet lectus proin nibh
            nisl condimentum id. Donec adipiscing tristique risus nec feugiat.
            Aliquet lectus proin nibh nisl condimentum id venenatis a
            condimentum. Suspendisse interdum consectetur libero id faucibus
            nisl tincidunt. Mauris vitae ultricies leo integer malesuada nunc
            vel. Augue ut lectus arcu bibendum. Morbi quis commodo odio aenean
            sed. Faucibus scelerisque eleifend donec pretium vulputate sapien
            nec sagittis. Tellus molestie nunc non blandit. Ut eu sem integer
            vitae justo eget. Id ornare arcu odio ut. Dictumst quisque sagittis
            purus sit amet volutpat. Malesuada fames ac turpis egestas sed
            tempus urna et pharetra. Semper viverra nam libero justo laoreet sit
            amet cursus sit. Nibh mauris cursus mattis molestie a iaculis at.
            Magnis dis parturient montes nascetur ridiculus mus mauris vitae.
            Vulputate dignissim suspendisse in est ante in nibh mauris cursus.
            Orci porta non pulvinar neque laoreet suspendisse. Enim facilisis
            gravida neque convallis. Tortor at auctor urna nunc id cursus metus.
            Eros donec ac odio tempor orci dapibus. Viverra aliquet eget sit
            amet tellus. Viverra justo nec ultrices dui sapien. Faucibus in
            ornare quam viverra orci sagittis eu volutpat odio. In ornare quam
            viverra orci sagittis eu volutpat. Suspendisse sed nisi lacus sed.
            Mauris sit amet massa vitae tortor. Vitae tempus quam pellentesque
            nec nam aliquam sem et. Nisi scelerisque eu ultrices vitae auctor eu
            augue ut. Et magnis dis parturient montes nascetur. Suscipit
            adipiscing bibendum est ultricies integer quis auctor. Felis donec
            et odio pellentesque diam volutpat commodo sed. Tempus urna et
            pharetra pharetra massa. Massa placerat duis ultricies lacus sed
            turpis. Iaculis nunc sed augue lacus. Molestie at elementum eu
            facilisis sed odio. Ac orci phasellus egestas tellus rutrum tellus
            pellentesque eu tincidunt. Et pharetra pharetra massa massa
            ultricies mi. Porttitor lacus luctus accumsan tortor posuere ac ut
            consequat. Tellus in hac habitasse platea. Maecenas pharetra
            convallis posuere morbi leo urna molestie at elementum. Amet risus
            nullam eget felis. Mollis nunc sed id semper risus. Orci dapibus
            ultrices in iaculis. Vulputate mi sit amet mauris commodo quis.
            Morbi leo urna molestie at. Vivamus at augue eget arcu. Imperdiet
            dui accumsan sit amet nulla facilisi morbi tempus iaculis. Lobortis
            mattis aliquam faucibus purus in massa tempor. Sapien et ligula
            ullamcorper malesuada proin libero nunc. Non pulvinar neque laoreet
            suspendisse interdum consectetur libero id. Sed enim ut sem viverra
            aliquet eget sit amet. Vitae proin sagittis nisl rhoncus mattis
            rhoncus urna neque. Pulvinar sapien et ligula ullamcorper malesuada
            proin libero nunc. Arcu bibendum at varius vel pharetra vel. Semper
            quis lectus nulla at volutpat diam ut venenatis. Ut tortor pretium
            viverra suspendisse potenti nullam ac tortor vitae. Habitant morbi
            tristique senectus et netus et malesuada. Blandit libero volutpat
            sed cras ornare arcu. Libero justo laoreet sit amet cursus sit amet.
            Ullamcorper eget nulla facilisi etiam dignissim. Dignissim diam quis
            enim lobortis scelerisque.
          </p>
        </Container>
      </Section>
    </Layout>
  );
};

export default TermsAndConditions;
